<template>
  <ValidationObserver ref="stickyToolbar">
    <div v-if="transactionStatus">
      <ul class="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
      <!-- CRM Record Link -->
        <li
          v-if="crmRecordLink"
          v-b-tooltip.hover.left="'Go to CRM Record'"
          class="nav-item"
        >
          <a
            target="_blank"
            :href="crmRecordLink"
            class="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary"
          >
            <img
              width="24px"
              height="24px"
              src="media/logos/Zoho_Icon.svg"
             alt="zoho-icon"/>
          </a>
        </li>

        <!-- Claim Form Link -->
        <li
          v-if="showClaimFormLink"
          v-b-tooltip.hover.left="claimFormTooltip"
          class="nav-item mt-2"
        >
          <a
            target="_blank"
            :href="claimFormLink"
            class="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary"
          >
            <i :class="claimFormIcon"></i>
          </a>
        </li>

        <!-- Box Folder Link -->
        <li
          v-if="transactionBoxFolderLink"
          v-b-tooltip.hover.left="'Go to Box Folder'"
          class="nav-item mt-2"
        >
          <a
            target="_blank"
            :href="transactionBoxFolderLink"
            class="btn btn-sm btn-icon svg-icon btn-bg-light btn-text-primary btn-hover-primary"
          >
            <inline-svg src="media/logos/Box_Icon.svg" />
          </a>
        </li>

        <!-- CRM Notes -->
        <li v-b-tooltip.hover.left="'Notes'" class="nav-item mt-2">
          <button
            class="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary"
            @click.prevent="openNotesDialog"
          >
            <i class="far fa-comment-dots text-primary"></i>
          </button>
        </li>

        <!-- CRM Tasks -->
        <li v-b-tooltip.hover.left="'Create Task'" class="nav-item mt-2">
          <button
            class="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary"
            @click.prevent="openAddTaskDialog"
          >
            <i class="fas fa-list text-primary"></i>
          </button>
        </li>

        <!-- Refresh Page -->
        <li v-b-tooltip.hover.left="'Refresh Data'" class="nav-item mt-2">
            <button @click="refreshPage" class="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary">
              <i class="fas fa-sync-alt"></i>
            </button>
        </li>

        <!-- Discard Transaction -->
        <li
          v-if="
            transactionStatus.status !== 'Completed' &&
            transactionStatus.hasOwnProperty('dueInWeek')
          "
          v-b-tooltip.hover.left="'Discard Transaction'"
          class="nav-item mt-2"
        >
          <button
            class="btn btn-sm btn-icon btn-bg-light btn-text-danger btn-hover-danger"
            @click.prevent="openDiscardTransactionDialog"
          >
            <i class="far fa-trash-alt text-danger"></i>
          </button>
        </li>

        <!-- Next Batch Transaction -->
        <li
          v-if="nextBatchTransaction"
          v-b-tooltip.hover.left="
            `Go To Next Transaction - (${nextBatchTransaction.transactionName})`
          "
          class="nav-item mt-2"
        >
          <button
            class="btn btn-sm btn-icon btn-bg-light btn-text-danger btn-hover-danger"
            @click.prevent="openGoToNextTransactionDialog"
          >
            <i class="fa fa-forward fa-2x text-warning"></i>
          </button>
        </li>
      </ul>

      <b-modal
        ref="notesDialog"
        title="Notes"
        size="xl"
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        scrollable
        ok-only
        ok-title="Close"
        @hidden="resetNote"
        @shown="refreshNotes"
      >
        <b-card border-variant="secondary" body-class="px-2 py-2">
          <h6>Add New Note</h6>
          <ValidationObserver ref="addNoteData">
            <!--            <div class="form-group row mb-2">-->
            <!--              <label class="col-2 col-form-label">Note Title</label>-->
            <!--              <div class="col-10">-->
            <!--                <ValidationProvider rules="required|max:120" name="noteTitle" v-slot="{ errors }">-->
            <!--                  <input class="form-control" v-model="noteTitle" maxlength="120" :disabled="savingNote"/>-->
            <!--                  <span class="text-danger">{{ errors[0] }}</span>-->
            <!--                </ValidationProvider>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="form-group row mb-2">
              <label class="col-2 col-form-label">Note Text</label>
              <div class="col-10">
                <ValidationProvider
                  rules="max:65535"
                  name="noteText"
                  v-slot="{ errors }"
                >
                  <textarea
                    class="form-control min-rows"
                    v-model="noteText"
                    maxlength="65535"
                    :disabled="savingNote"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </ValidationObserver>
          <div class="form-group row mb-0">
            <div class="col-2">
              <button
                class="btn btn-primary form-control"
                :disabled="savingNote"
                @click.prevent="addNote"
              >
                Add Note
              </button>
              <b-spinner
                class="align-middle"
                variant="primary"
                v-if="savingNote"
              ></b-spinner>
            </div>
          </div>
        </b-card>

        <div class="mt-5">
          <h4>Notes History</h4>
          <span
            v-if="!Array.isArray(notes) || notes.length === 0"
            class="text-secondary mt-2"
            >No Notes Available</span
          >
          <div
            class="form-group row mb-0 d-flex p-5"
            v-for="(note, index) in notes"
            :key="index"
          >
            <div class="text-icon">
              <i class="far fa-file-alt fa-md font-size-h6"></i>
            </div>
            <div class="text-group">
              <div class="col-12">
                <label class="font-size-h6">{{ note.Note_Title }} - </label
                ><span class="font-italic font-size-sm">{{
                  note.K_CreatedTime
                }}</span>
              </div>
              <div class="col-12">
                <p class="font-weight-bold">
                  <span v-html="note.Note_Content"></span>
                </p>
              </div>
              <!--              <div class="col-12 d-flex-inline font-italic font-size-sm">-->
              <!--                <span>-->
              <!--                  {{ note.K_CreatedTime }}-->
              <!--                </span>-->
              <!--              </div>-->
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal
        ref="addTaskDialog"
        title="Add Task"
        size="lg"
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        @ok="addTask"
        @hidden="resetTask"
      >
        <div class="form-group row mt-5">
          <label class="col-2 col-form-label"
            >Subject<span class="text-danger">*</span></label
          >
          <div class="col-10">
            <ValidationProvider
              rules="required|max:255"
              name="subject"
              v-slot="{ errors }"
            >
              <input
                class="form-control"
                v-model="task.subject"
                maxlength="255"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-2 col-form-label"
            >Due Date<span class="text-danger">*</span></label
          >
          <div class="col-4">
            <ValidationProvider
              rules="required"
              name="dueDate"
              v-slot="{ errors }"
            >
              <b-form-datepicker
                class="form-control"
                :locale="'en-AU'"
                v-model="task.dueDate"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
              >
              </b-form-datepicker>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <label class="col-2 col-form-label"
            >Priority<span class="text-danger">*</span></label
          >
          <div class="col-4">
            <ValidationProvider
              rules="required"
              name="priority"
              v-slot="{ errors }"
            >
              <b-form-select class="form-control" v-model="task.priority">
                <b-form-select-option value=""></b-form-select-option>
                <b-form-select-option value="Urgent"
                  >Urgent</b-form-select-option
                >
                <b-form-select-option value="High">High</b-form-select-option>
                <b-form-select-option value="Medium"
                  >Medium</b-form-select-option
                >
                <b-form-select-option value="Low">Low</b-form-select-option>
              </b-form-select>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-2 col-form-label">Category</label>
          <div class="col-4">
            <b-form-select class="form-control" v-model="task.category">
              <b-form-select-option value=""></b-form-select-option>
              <b-form-select-option value="COC">COC</b-form-select-option>
              <b-form-select-option value="Insurer Query"
                >Insurer Query</b-form-select-option
              >
              <b-form-select-option value="Client Query"
                >Client Query</b-form-select-option
              >
              <b-form-select-option value="Claims Insurer Query"
                >Claims Insurer Query</b-form-select-option
              >
              <b-form-select-option value="Claims Client Query"
                >Claims Client Query</b-form-select-option
              >
              <b-form-select-option value="Other">Other</b-form-select-option>
            </b-form-select>
          </div>
          <label class="col-2 col-form-label"
            >Owner<span class="text-danger">*</span></label
          >
          <div class="col-4">
            <ValidationProvider
              rules="required"
              name="owner"
              v-slot="{ errors }"
            >
              <b-form-select
                class="form-control"
                v-model="task.ownerId"
                :options="activeUsers"
              >
              </b-form-select>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-2 col-form-label">Description</label>
          <div class="col-10">
            <ValidationProvider
              rules="max:32000"
              name="description"
              v-slot="{ errors }"
            >
              <textarea
                class="form-control min-rows"
                v-model="task.description"
                maxlength="32000"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
      </b-modal>

      <b-modal
        ref="discardTransactionDialog"
        title="Discard Transaction"
        size="lg"
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        :ok-disabled="transactionDiscarded"
        :cancel-disabled="transactionDiscarded"
        @ok="discardTransaction"
        @hidden="resetDiscardDetails"
      >
      <!-- Show alert when transaction is discarded -->
      <b-alert v-if="transactionDiscarded" variant="warning" show>
        <div class="text-dark">
          This Renewal transaction was discarded. Please close this window and choose a different renewal.
        </div>
      </b-alert>

      <!-- Hide other contents when transaction is discarded -->
      <template v-else>
        <strong class="text-danger"
            >Please note that this action cannot be reverted.</strong
          ><br /><br />

          Select the reason for discarding this transaction:
          <div class="form-group">
            <ValidationProvider
              rules="required"
              name="discardReason"
              v-slot="{ errors }"
            >
              <b-form-select class="form-control" v-model="discardReason">
                <b-form-select-option value=""></b-form-select-option>
                <b-form-select-option value="Client decided not to proceed"
                  >Client decided not to proceed</b-form-select-option
                >
                <b-form-select-option value="Client ceased communicating"
                  >Client ceased communicating</b-form-select-option
                >
                <b-form-select-option value="Declined by insurers"
                  >Declined by insurers</b-form-select-option
                >
                <b-form-select-option value="No longer in CIB risk profile"
                  >No longer in CIB risk profile</b-form-select-option
                >
                <b-form-select-option value="Other">Other</b-form-select-option>
              </b-form-select>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
            <div class="mt-5">
              Details:
              <ValidationProvider
                rules="required"
                name="discardDetails"
                v-slot="{ errors }"
              >
                <b-textarea
                  class="form-control"
                  rows="4"
                  v-model="discardDetails"
                  trim
                ></b-textarea>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
      </template>


      </b-modal>

      <b-modal
        ref="goToNextTransactionDialog"
        title="Go To Next Transaction"
        size="lg"
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        @ok="goToNextTransaction"
      >
        <span class="font-weight-bold font-size-h6">
          Are you sure you want to navigate to the next transaction?
          <br /><br />
          Unsaved changes from the current transaction will be lost.
        </span>
      </b-modal>
    </div>
  </ValidationObserver>
</template>

<style>
.form-control.min-rows {
  min-height: calc(1.5em * 3 + 2px);
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: "KTStickyToolbar",

  data() {
    return {
      activeUsers: [],
      notes: [],
      noteTitle: null,
      noteText: null,
      savingNote: false,
      task: { priority: "Medium" },
      savingTask: false,
      discardReason: null,
      discardDetails: null,
      transactionDiscarded: false
    };
  },

  mounted() {
    this.refreshNotes();

    this.$commonDataService
      .getActiveUsers()
      .then(({ data }) => {
        let users = data.result || [];
        if (Array.isArray(users)) {
          users.forEach((user) => {
            this.activeUsers.push({
              text: user.full_name,
              value: user.id,
            });
          });
        }
      })
      .catch((error) => {
        // TODO Handle error
        console.log(error);
      });
  },

  methods: {
    refreshPage() {
      window.location.reload(); // Forces a page reload
    },

    refreshNotes: function () {
      this.$commonDataService
        .getNotes()
        .then(({ data }) => {
          let newNotes = data.result || [];
          this.notes.splice(0, this.notes.length);
          newNotes.forEach((note) => {
            this.notes.push(note);
          });
        })
        .catch((error) => {
          // TODO Handle error
          console.log(error);
        });
    },

    openNotesDialog: function () {
      this.$refs["notesDialog"].show();
    },

    addNote: function () {
      if (!this.noteText || !this.noteText.trim()) {
        return;
      }
      this.$refs.addNoteData.validate().then((isValid) => {
        if (isValid) {
          this.savingNote = true;
          this.$commonDataService
            .addNote(/*this.noteTitle, */ this.noteText)
            .then(() => {
              this.refreshNotes();
              this.resetNote();
              this.savingNote = false;
            })
            .catch((error) => {
              // TODO Handle error
              this.savingNote = false;
              console.log(error);
            });
        }
      });
    },

    resetNote: function () {
      this.noteTitle = this.noteText = null;
    },

    openAddTaskDialog: function () {
      this.$refs["addTaskDialog"].show();
    },

    addTask: function (bvModalEvt) {
      bvModalEvt.preventDefault();

      this.$refs.stickyToolbar.validate().then((isValid) => {
        if (isValid) {
          this.savingTask = true;
          this.$commonDataService
            .addTask(this.task)
            .then(() => {
              this.resetTask();
              this.$refs["addTaskDialog"].hide();
              this.savingTask = false;
            })
            .catch((error) => {
              // TODO Handle error
              console.log(error);
              this.savingTask = false;
            });
        }
      });
    },

    resetTask: function () {
      this.task = { priority: "Medium" };
    },

    openDiscardTransactionDialog: function () {
      this.$refs["discardTransactionDialog"].show();
    },

    discardTransaction: function (bvModalEvt) {
      bvModalEvt.preventDefault();

      this.$refs.stickyToolbar.validate().then((isValid) => {
        if (isValid) {
          // TODO Show progress indicator
          this.$commonDataService
            .discardTransaction(this.discardReason, this.discardDetails)
            .then(() => {
              this.resetDiscardDetails();
              // this.$refs["discardTransactionDialog"].hide();
            })
            .catch((error) => {
              // TODO Handle error
              console.log(error);
            });
        }
      });
      this.transactionDiscarded = true;
    },

    resetDiscardDetails: function () {
      this.discardReason = null;
      this.discardDetails = null;
    },

    openGoToNextTransactionDialog: function () {
      this.$refs["goToNextTransactionDialog"].show();
    },

    goToNextTransaction: function (bvModalEvt) {
      bvModalEvt.preventDefault();

      window.parent.location.href =
        this.nextBatchTransaction.transactionProcessingLink;
    },
  },

  computed: {
    ...mapState([
        'claimFormViewLink',
        'claimFormInternalLink',
        'insurerNotified'
      ]),
    ...mapGetters(['isClaimLodged']),

    claimFormLink() {
      return this.insurerNotified ? this.claimFormViewLink : this.claimFormInternalLink;
    },

    claimFormTooltip() {
      return this.insurerNotified ? 'View Claim Form' : 'Edit Claim Form';
    },

    claimFormIcon() {
      return this.insurerNotified ? 'fa-solid fa-glasses text-primary' : 'far fa-edit text-primary';
    },

    showClaimFormLink() {
      return this.insurerNotified ? !!this.claimFormViewLink : !!this.claimFormInternalLink;
    },

    transactionStatus: function () {
      return this.$store.state.transactionStatus;
    },

    transactionBoxFolderLink: function () {
      return this.$store.state.transactionBoxFolderLink;
    },

    crmRecordLink: function () {
      return this.$store.state.crmRecordLink;
    },

    nextBatchTransaction: function () {
      return this.$store.state.nextBatchTransaction;
    },
  },
};
</script>
